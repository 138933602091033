import moment from "moment";
const currentDay = () => {
  const start =  moment().startOf('day').format('YYYY-MM-DD');
  const end =  moment().endOf('day').format('YYYY-MM-DD');
  return { start, end };
}

const currentWeek = () => {
  const start =  moment().startOf('week').format('YYYY-MM-DD');
  const end =  moment().endOf('week').format('YYYY-MM-DD');
  return { start, end };
}

const previousWeek = () => {
  const start = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
  const end = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
  return { start, end };
}

const getCurrentMonth = () => {
  var date = new Date();
  var start = new Date(date.getFullYear(), date.getMonth(), 1);
  var end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { start, end };
}

const getPreviousMonth = () => {
  var date = new Date();
  var start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  var end = new Date(date.getFullYear(), date.getMonth(), 0);
  return { start, end };
}

const getPreviousTwoMonths = () => {
  var date = new Date();
  var start = new Date(date.getFullYear(), date.getMonth() - 2, 1);
  var end = new Date(date.getFullYear(), date.getMonth(), 0);
  return { start, end };
}

const getPreviousQuarter = () => {
  var date = new Date();
  var start = new Date(date.getFullYear(), date.getMonth() - 3, 1);
  var end = new Date(date.getFullYear(), date.getMonth(), 0);
  return { start, end };
}


export default [
  { label: 'Selecionar', value: { start: null, end: null, placeholder: true } },
  { label: 'Hoje', value: currentDay()},
  { label: 'Semana atual', value: currentWeek() },
  { label: 'Semana anterior', value: previousWeek() },
  { label: 'Mês atual', value: getCurrentMonth() },
  { label: 'Mês anterior', value: getPreviousMonth() },
  { label: 'Último bimestre', value: getPreviousTwoMonths() },
  { label: 'Último trimestre', value: getPreviousQuarter() },
  { label: 'Personalizado', value: null },
];
