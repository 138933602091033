<template>
  <div class="pending-requests">
    <div class="pending-requests-header">
      <p class="highlight">
        <strong>Exames</strong>
      </p>
      <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && searchPatient()"/>
    </div>

    <div class="filter">
      <b-row>
        <b-col cols="6">
          <b-form-group class="patient-search mt-4">
            <label for="search">Nome do paciente</label>
            <b-input
              autocomplete="off"
              type="text"
              v-model="filter.name"
              class="form-control"
              @input="debounceSearchPatients"
              placeholder="Pesquisar paciente"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group class="mt-4">
            <div class="search-by-state ">
              <label class="state-label" for="search_state">Situação</label>
              <Select v-model="filter.state" :options="filterStates" @input="(value) => searchByState(value)"
                size="sm" />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group class="mt-4">
            <div class="search-by-state ">
              <label class="state-label" for="search_state">Período</label>
              <v-periods class="select-label" @select="searchByPeriod" 
                size="sm" />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group class="mt-4">
            <div class="search-by-state ">
              <label class="state-label" for="search_state">Telemedicina</label>
              <Select v-model="filter.state" :options="filterTelemedicina" @input="(value) => searchByPendencies(value)"
                size="sm" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div class="tab-content p-0">
      <perfect-scrollbar>
        <b-table-simple striped responsive v-if="patients">
          <tbody>
            <tr class="tr-header">
              <th class="col-md-3">Paciente</th>
              <th class="col-md-2">Situação</th>
              <th class="col-md-2">Data da solicitação</th>
              <th class="col-md-2">Acuidade visual</th>
              <th class="col-md-1">Prontuário</th>
              <th class="col-md-1">Exames</th>
              <th class="col-md-1">Telemedicina</th>
            </tr>

            <tr v-for="(patient, index) in patients" :key="`patient-${index}`" class="tr-header">
              <td class="col-md-3 pl-0">
                <div class="patient">
                  <Avatar :src="`${patient.picture}?x=${moment()}`" class="patient-avatar" />
                  <div class="patient-info">
                    <div class="patient-medical-record">
                      <Ellipsis class="patient-name">
                        {{ patient.name }}
                      </Ellipsis>
                    </div>
                    <div class="patient-age" v-if="patient !== null && moment(patient.birthday).isValid()">
                      {{ moment(patient.birthday).format('DD/MM/YYYY') }}
                      ({{ moment().diff(moment(patient.birthday), 'years') }} anos)
                    </div>
                  </div>
                </div>
              </td>

              <td class="col-md-2">
                <b-button :variant="requestState(patient)" class="btn-pending-state" pill disabled>
                  <p><strong>{{ showRequestState(patient) }}</strong></p>
                </b-button>
              </td>

              <td class="col-md-2">
                <b-row class="mt-3 pl-2">
                  {{ moment(patient.requested_at).format('DD/MM/YYYY') }}
                </b-row>
              </td>

              <td class="col-md-2">
                <b-row cols="8" class="mb-1">
                  <b-col align-v="center">
                    <p>
                      <b-badge variant="light" class="eye-badge">
                        <Eye class="eye-icon" />
                        <span>D</span>
                      </b-badge>
                      <span>{{ patient.acuity_right }}</span>
                    </p>
                  </b-col>
                </b-row>
                <b-row cols="8">
                  <b-col align-v="center">
                    <p>
                      <b-badge variant="light" class="eye-badge">
                        <Eye class="eye-icon" />
                        <span>E</span>
                      </b-badge>
                      <span>{{ patient.acuity_left }}</span>
                    </p>
                  </b-col>
                </b-row>
              </td>

              <td class="col-md-1">
                <b-row class="mt-2" align-v="center">
                  <b-col>
                    <b-button block variant="outline-primary"
                      @click="goToMedicalRecord('/pacientes/' + patient.person_id)">
                      Abrir
                    </b-button>
                  </b-col>
                </b-row>
              </td>

              <td class="col-md-1">
                <b-row class="mt-2" align-v="center">
                  <b-col>
                    <b-button block variant="primary" :disabled="isAttendanceAvailable(patient)"
                      v-if="clinic.role === 'DOCTOR' || clinic.role === 'DOCTOR_MANAGER'"
                      @click="startAttendance(patient.person_id)">
                      Validar
                    </b-button>
                  </b-col>
                </b-row>
              </td>

              <td class="col-md-1 pad-left">
                <b-row class="mt-2" align-v="center">
                  <b-col>
                    <span v-if="patient.pendencies === 'NONE'">Opcional</span>
                    <span v-else>Obrigatória</span>
                  </b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
        <p class="no-results" v-show="!patients.length">Nenhum resultado encontrado</p>
      </perfect-scrollbar>
      <div class="pagination-position">
        <b-pagination v-model="filter.page" :total-rows="filter.count" :per-page="filter.limit" @change="handlePagination" first-number last-number size="sm" />
      </div>
    </div>
  </div>
</template>
<script>
import apiModule from '@/modules/bvPendingRequests/api'
import { debounce } from 'lodash';
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import Periods from '@/components/General/Periods'
import moment from 'moment';

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Eyecare - Solicitações pendentes'
  },
  components: {
    'v-periods': Periods,
    Select: () => import('@/components/Select'),
    Avatar: () => import('@/components/General/Avatar'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Eye: () => import('@/assets/icons/eye.svg'),
    Loading: () => import('@/assets/icons/loading.svg'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      loading: false,
      patients: [],
      debounceSearchPatients: debounce(this.searchPatient, 500),
      filter: {
        name: null,
        state: 0,
        dates: null,
        page: 1,
        count: 0,
        limit: 20,
        pendencies: null,
      },
      filterPatient: [],
      filterStates: [
        { 'label': 'Selecionar', 'value': null },
        { 'label': 'Pendente', 'value': 0, 'appearance': 'warning' },
        { 'label': 'Realizado', 'value': 1, 'appearance': 'success' },
        { 'label': 'Indeferido', 'value': 2, 'appearance': 'danger' }
      ],
      filterTelemedicina: [
        { 'label': 'Selecionar', 'value': null },
        { 'label': 'Opcional', 'value': 'NONE' },
        { 'label': 'Obrigatorio', 'value': 'TELEMEDICINE' },
      ],
    }
  },
  async created() {
    await this.getPendingRequests()
  },
  methods: {
    async getPendingRequests() {
      this.loading = true

      try {
        const { data: response } = await apiModule.getBvPendingRequestsExam(this.clinic.id, this.filter)
        this.patients = response.data
        this.filterPatient = response.data.map(patient => ({ name: patient.name }))
        this.filter.count = response.total
        this.filter.limit = response.per_page
      } catch (err) {
        this.$toast.error('Não foi possível obter a lista de solicitações pendentes')
      } finally {
        this.loading = false
      }
    },
    async getDefaultForm() {
      try {
        const { data } = await this.api.getForms(this.clinic.id, this.user.id, 'ATTENDANCE');
        const form = data.find(el => {return el.name === 'Benefício Visão'});
        if (form) {
          return form.id
        }
        return null
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async startAttendance(patientId) {
      const isLoading = this.$loading.show()
      try {
        const formId = await this.getDefaultForm()
        const { data } = await this.api.startAttendance(this.clinic.id, patientId, null, formId, 'BV_EXAM')

        await this.$router.push('/pacientes/' + patientId + '/consulta/' + data.id)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getState(patient) {
      return this.filterStates.find(state => state.value === patient.state)
    },
    requestState(patient) {
      const { appearance } = this.getState(patient)
      return appearance
    },
    showRequestState(patient) {
      const { label } = this.getState(patient)
      return label
    },
    async handlePagination() {
      await this.getPendingRequests()
    },
    goToMedicalRecord(path) {
      this.$router.push(path);
    },
    isAttendanceAvailable(patient) {
      return patient.state !== 0
    },
    isRefusable(patient) {
      return patient.state === 0
    },
    async refuse(patient) {
      const name = patient?.name
      const res = await this.$swal({
        title: 'Indeferir solicitação',
        html: `<div class="body-alert">Você tem certeza que deseja
          dar como "<span class="color-danger">indeferida</span>"
          a solicitação de prescrição de óculos do(a) paciente </div>
          <span class="color-primary">${name}</span>?<br /><br />
          <div>Esta ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCloseButton: true,
        reverseButtons: true,
        customClass: {
          cancelButton: 'btn-cancel',
          confirmButton: 'btn-primary',
        },
       })
      if (res.isConfirmed) {
        const isLoading = this.$loading.show()
        const idRequest = patient?.pending_request_id
        try {
          await apiModule.updateBvPendingRequests(idRequest)
          this.$toast.success('Solicitação indeferida com sucesso!');
          await this.getPendingRequests()
        }
        catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    async searchPatient(query) {
      this.filter.name = query

      await this.getPendingRequests()
    },
    async searchByState(value) {
      this.filter.state = value
      await this.getPendingRequests()
    },
    async searchByPendencies(value) {
      this.filter.pendencies = value
      console.log("AAABB", value)
      await this.getPendingRequests()
    },
    async searchByPeriod(value) {
      const { start, end } = value

      if (start === null && end === null) {
        this.filter.dates = null
        return await this.getPendingRequests()
      }

      this.filter.dates = {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD')
      };

      await this.getPendingRequests()
    },
  },
}
</script>
<style lang="scss" scoped>
.pending-requests {
  height: 100%;
  padding: 30px 2px;
  text-align: left;
  .filter {
    margin-bottom: 30px;
    margin-top: -25px;

    label {
      font-weight: bold;
    }

    .search-by-state {         
      input {
        margin-top: 5px;
        font-size: 16px;
        padding: 10px 10px;
        min-height: 38px;
        background-color: white !important;
      }

      svg {
        margin-top: 10px;
      }

      .state-label {
        margin-bottom: 8px !important;
      }

    }

  }

  .patient-search {
    .multiselect__tags {
      margin-top: 5px;
      padding: 5px 0;
    }

    input {
      margin-top: 5px;
      padding: 10px 10px;
    }
  }

  .select-label {
    margin-bottom: 8px !important;
  }
  .tab-content {
    padding-bottom: 30px;

    .pad-left {
      padding-left: 28px !important;
    }
    .patient {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      margin-top: 5px;

      .patient-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        padding-left: 10px;
      }

      .patient-medical-record {
        width: 100%;

        a {
          cursor: pointer;
        }
      }

      .patient-age {
        font-size: 14px;
        align-self: flex-start;
      }
    }

    .eye-badge {
      background-color: #D9DFF2;
      width: 48px;
      margin-right: 8px;

      .eye-icon {
        width: 20px;
        padding-right: 5px;
        margin: 0 auto;
      }
    }

    .btn-pending-state {
      margin-top: 10px;
      font-size: 12px;
      border: none;

      &.btn-danger {
        background-color: #FFCECA !important;
        p {
          color: #D82828 !important;
        }
      }
      &.btn-success {
        background-color: #DBFFF0 !important;
        p {
          color: #00C773 !important;
        }
      }
      &.btn-warning {
        background-color: #FFEBCC !important;
        p {
          color: #525C7A !important;
        }
      }
    }

    .btn-refuse {
      background-color: #FFEBCC;
      color: #FF6B00;
      font-weight: bolder;
      font-size: 15px;
      border: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 24px 0;
        border-bottom: 1px solid var(--neutral-200);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .name {
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--type-active);
        }
      }
    }
  }
      
  .container {
    width: 100%;
    max-width: 100%;
    padding: 0;

    .white-box {
      width: 100%;
      background-color: var(--neutral-000);
      border-radius: 8px;
      margin-bottom: 16px;


    }
  }
}
.pending-requests-header {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
</style>
<style lang="scss">
.swal2-modal {
  font-family: 'Nunito Sans';

  .swal2-title {
    font-size: 18px;
    color: var(--type-active);
    border: 1px solid var(--neutral-200);
    padding-bottom: 15px;
  }

  .swal2-select {
    margin-top: 1%;
    border-radius: 8px;
    padding: 0.375em 0.625em;
    border-color: var(--type-placeholder);
    font-weight: 400;
    color: var(--type-active);
    cursor: pointer;
  }

  .swal2-actions {
    justify-content: end;
    margin-right: 20px;
  }

  .swal2-html-container {
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  button {
    font-weight: bold;
  }

  .color-primary {
    color: var(--blue-500);
  }

  .color-danger {
    color: #FF6B00;
  }

  .btn-cancel {
    background: none;
    line-height: 24px; 
    color: #F63220;
  }
}
</style>