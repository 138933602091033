import { base } from '@/utils/api'

export default {
    getBvPendingRequests(clinicId, query) {
        return base.get(`/bvPendingRequests/${clinicId}`, {
            params: query
        })
    },
    updateBvPendingRequests(id) {
        return base.put(`/bvPendingRequests/${id}`)
    },
    getBvPendingRequestsExam(clinicId, query) {
        return base.get(`/bvPendingRequestsExam/${clinicId}`, {
            params: query
        })
    },
    getBvPendingRequestPrescriptionOld(id) {
        return base.get(`/bv/pending-request/${id}/prescription`)
    },
}