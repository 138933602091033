<template>
  <div class="periods-container">
    <Select
      v-show="!custom"
      :label="label"
      v-model="periodObject"
      placeholder="Selecionar data"
      :options="periods"
      @onChange="onChange"
      :monthSelected="monthSelected"
      :daySelected="daySelected"
    />
    <b-form-group v-show="custom">
      <label v-if="label" for="date" class="label">
        {{ label }}
      </label>
      <date-picker
        range
        ref="datepicker"
        class="full"
        format="DD MMM"
        v-model="period"
        :clearable="true"
        :lang="langDatePicker"
      ></date-picker>
        <!-- <v-close
          v-if="custom"
          class="close-icon"
          :style="cssProps"
          @click="clearCustom"
        /> -->

    </b-form-group>
  </div>
</template>
<script>
import periods from '@/utils/periods'
export default {
  name: 'periods',
  components: {
    Select: () => import('@/components/Select'),
  },
  props: {
    label: String,
    top: String,
    clearSelection: Boolean,
    monthSelected: Boolean,
    daySelected: Boolean,
  },
  data() {
    return {
      periods,
      custom: false,
      period: [],
      periodObject: { start: null, end: null },
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
    }
  },
  computed: {
    cssProps() {
      return {
        '--top': this.top ?? '28%',
      }
    }
  },
  methods: {
    onChange(props) {
      if (props) {
        this.dispatchPeriod(props.start, props.end);
        return;
      }

      this.custom = true;
      this.$refs.datepicker.openPopup();
    },
    dispatchPeriod(start, end) {
      this.$emit('select', { start, end });
    },
    clearCustom() {
      this.custom = false;
      this.periodObject = { start: null, end: null };
      this.dispatchPeriod(null, null);
    }
  },
  watch: {
    period: function() {
      if (!this.period[0]) {
        this.periodObject = periods[0]
        this.custom = false;
      }
      this.dispatchPeriod(this.period[0], this.period[1])
    },
    clearSelection(newValue) {
      if (newValue) {
        this.custom = false;
        this.periodObject = { start: null, end: null };
        this.dispatchPeriod(null, null);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .periods-container {
    position: relative;
  }
  .label {
    width: 100%;
    text-align: left;
  }
  .close-icon {
    width: 25px;
    height: 25px;
    fill: rgb(127, 145, 210);
    position: absolute;
    right: 15px;
    top: var(--top) !important;
  }
</style>
